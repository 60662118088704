/*
* 업무구분: 고객
* 화 면 명: MSPFS105M
* 화면설명: 거래내역조회2515
* 작 성 일: 2023.03.30
* 작 성 자: 이태흥
* 수정일 : 2024-02-18 강현철  사유 : ASR250100285 사랑온 수익증권 외납세 개발 조회항목 개발
*/
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left mr0 gap40 w100p">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호 </label>
                  <mo-decimal-field
                    numeric
                    ref="ed_accn_no"
                    class="w130 input-account"
                    :class="lv_ed_accn_no.error ? 'error' : ''"
                    v-model="lv_ed_accn_no.value"
                    @keyup="fn_SearchValid($event)"
                    @paste="fn_SearchValid($event, 'P')"
                    mask="#######-###"
                  />
                  <mo-dropdown ref="ed_acct_gb" :items="lv_acct_list" v-model="lv_ed_acct_gb.value" class="w340" placeholder=" " :disabled="lv_ed_acct_gb.disabled" />
                  <mo-text-field v-model="lv_ed_cust_nm.value" class="w100" disabled />
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 거래구분 </label>
                  <mo-dropdown :items="lv_commCode.code301" v-model="lv_ed_trd_kind.value" class="w150" />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left mr0 gap40">
                <div class="wrap-input row">
                  <label class="emphasis"> 기준일 </label>
                  <mo-date-picker v-model="lv_ed_stc_from.value" :bottom="false" class="w110" no-placeholder />
                  <i> ~ </i>
                  <mo-date-picker v-model="lv_ed_stc_to.value" :bottom="false" class="w110" no-placeholder />
                </div>
                <div class="wrap-input row">
                  <label> 매체구분 </label>
                  <mo-dropdown :items="mediaType" v-model="lv_ed_media_gb.value" class="w143" />
                </div>
                <div class="wrap-input row">
                  <label> 적요상세 </label>
                  <mo-radio value="y" class="mr14" disabled> 예 </mo-radio>
                  <mo-radio v-model="lv_ed_summ_detail_yn.value"> 아니오 </mo-radio>
                  <!-- <mo-radio-wrapper
                      :items="summDetailYn"
                      v-model="lv_ed_summ_detail_yn.value"
                      class="row"
                      disabled
                    /> -->
                </div>
              </div>
              <div class="right w100p">
                <div class="wrap-button row">
                  <mo-button class="btn-clear" @click="fn_Init()"> 초기화 </mo-button>
                  <mo-button primary class="btn-inquiry" @click="fn_TradeListSearch()"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info ref="fsCustInfo" :propObj="pCustInfoObj" @fs-cust-name="fn_SetEdCustName"></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container>
        <div class="right-bg-white mt20">
          <div class="wrap-table x-scroll h-scroll">
            <table class="table row-type th-wsN">
              <thead>
                <tr>
                  <th>취소여부</th>
                  <th>거래일자</th>
                  <th>거래번호</th>
                  <th>적요명</th>
                  <th>거래금액</th>
                  <th>거래좌수</th>
                  <th>잔고평가금액</th>
                  <th>잔고원금</th>
                  <th>잔고좌수(금액)</th>
                  <th>은행명</th>
                  <th>은행계좌번호</th>
                  <th>외납세공제액</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in lv_trd_list" :key="i" :class="{ checked: row.canc_yn == 'Y' }">
                  <td>{{ row.canc_yn }}</td>
                  <td class="wsN">{{ row.trd_dt }}</td>
                  <td>{{ row.trd_no }}</td>
                  <td class="aC">{{ row.summ_cd }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.trd_amt) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.trd_qty) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.paym_possamt) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.prnc_blnc_aggr_amt) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.remn_qty) }}</td>
                  <td>{{ row.trsf_bfcer_bnk_nm }}</td>
                  <td>{{ row.bnk_accn_no_encr }}</td>
                  <!-- ASR250100285 사랑온 수익증권 외납세 개발 조회항목 개발 25.02.18 -->
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.sbtr_frg_ptax_amt) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ur-box-container>
    </main>

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo' // 고객정보 (공통)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS105M',
  screenId: 'MSPFS105M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
  },
  mounted() {
    this.fn_Init()

    this.$bizUtil.insSrnLog('MSPFS105M')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '거래내역조회',
        step: ''
      },
      pAlertPopupObj: {},
      lv_commCode: {},
      pCustInfoObj: {},

      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

      // 검색조건
      lv_ed_accn_no: {}, // 계좌번호
      lv_ed_acct_gb: {}, // 계좌종류
      lv_ed_cust_nm: {}, // 고객명
      lv_ed_trd_kind: {}, // 거래구분

      lv_ed_stc_from: {}, // 기준일시작
      lv_ed_stc_to: {}, // 기준일끝

      lv_ed_media_gb: {}, // 매체구분
      lv_ed_summ_detail_yn: {}, // 적요상세

      // 거래내역정보
      lv_cust_info: {}, // 고객정보
      lv_acct_list: [], // 계좌조회목록
      lv_trd_list: [], // 거래내역조회결과

      // 고객정보 펼치기/접기
      isActive: false,

      /***********************************************************************************
       * F10570142 : 거래내역조회(목록조회)
       * F10570208 : 계좌목록조회
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    summDetailYn() {
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },

    mediaType() {
      let rtn = []
      rtn.push({ value: '0', text: '전체' })
      rtn.push({ value: '1', text: '영업점' })
      rtn.push({ value: '2', text: '인터넷' })
      rtn.push({ value: '4', text: '모바일' })
      rtn.push({ value: '3', text: '콜센터' })

      rtn.push({ value: '5', text: '태블릿내방' })
      rtn.push({ value: '6', text: '태블릿방판' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 기준일시작
    'lv_ed_stc_from.value': {
      handler(after, before) {
        if (after.replace(/-/g, '') > this.lv_ed_stc_to.value.replace(/-/g, '')) {
          FSCommUtil.gfn_validate(this, '시작일은 종료일보다 클 수 없습니다.')

          setTimeout(() => {
            this.lv_ed_stc_from.value = FSCommUtil.gfn_dateReplace(1, this.fn_MinusYear())
          }, 50)
        }
      },
      deep: true
    },

    // 기준일끝
    'lv_ed_stc_to.value': {
      handler(after, before) {
        if (after.replace(/-/g, '') < this.lv_ed_stc_from.value.replace(/-/g, '')) {
          FSCommUtil.gfn_validate(this, '시작일은 종료일보다 클 수 없습니다.')

          setTimeout(() => {
            this.lv_ed_stc_to.value = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
          }, 50)
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [{ cId: '301', dayChkYn: 'N' }]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS105M] fn_Init...')

      // 검색조건
      this.lv_ed_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no') // 계좌번호
      this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb') // 계좌종류
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1) // 고객명
      this.lv_ed_trd_kind = FSCommUtil.gfn_dataSet(0, '00', false, '', 'ed_trd_kind') // 거래구분

      this.lv_ed_stc_from = FSCommUtil.gfn_dataSet(0, FSCommUtil.gfn_dateReplace(1, this.fn_MinusYear())) // 기준일시작
      this.lv_ed_stc_to = FSCommUtil.gfn_dataSet(0, FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))) // 기준일끝

      this.lv_ed_media_gb = FSCommUtil.gfn_dataSet(0, '0') // 매체구분
      this.lv_ed_summ_detail_yn = FSCommUtil.gfn_dataSet(0, true) // 적요상세

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init()

      // 거래내역정보
      this.lv_cust_info = {} // 고객정보
      this.lv_acct_list = [] // 계좌조회목록
      this.lv_trd_list = [] // 거래내역조회결과
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_IsActive() {
      this.isActive = !this.isActive
    },

    /******************************************************************************
     * Function명 : fn_MinusYear
     * 설명       : 1년전 날짜구하기
     ******************************************************************************/
    fn_MinusYear() {
      let objDate = new Date()
      objDate.setFullYear(objDate.getFullYear() - 1)
      let month = objDate.getMonth() + 1
      return objDate.getFullYear().toString() + (month.toString().length == 1 ? '0' : '') + month.toString() + (objDate.getDate().toString().length == 1 ? '0' : '') + objDate.getDate().toString()
    },

    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....')

      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()
    },

    fn_SetEdCustName(val) {
      // 고객명
      this.lv_ed_cust_nm.value = val
    },

    /******************************************************************************
     * Function명 : fn_AccnNoList, fn_AccnNoListResult
     * 설명       : 계좌목록조회
     ******************************************************************************/
    fn_AccnNoList(pAccnNo) {
      console.log('fn_AccnNoList.....')

      let t_data = { bfcer_cstgr_no: pAccnNo }
      FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
    },

    fn_AccnNoListResult(pResultData) {
      console.log('fn_AccnNoListResult.....')

      let t_data = pResultData.data.sy000000_O_00VO
      console.log(t_data)

      // 계좌목록조회 초기화
      this.lv_acct_list = []
      this.lv_ed_acct_gb.disabled = true

      if (t_data.length > 0) {
        t_data.forEach((item, idx) => {
          this.lv_acct_list.push({ value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no })
        })

        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb.value = 0
        this.lv_ed_acct_gb.disabled = false

        // 거래내역조회 결과 초기화
        this.lv_trd_list = []

        // 고객정보조회
        this.fn_CustInfo()
      } else {
        let t_popupObj = { content: '검색할 계좌가 없습니다.' }
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_TradeListSearch, fn_TradeListSearchResult
     * 설명       : 거래내역조회(목록조회)
     ******************************************************************************/
    fn_TradeListSearch() {
      console.log('fn_TradeListSearch.....')

      this.lv_ed_accn_no.error = false

      let t_accn_no = this.lv_ed_accn_no.value ? this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0, 10) : ''

      if (FSCommUtil.gfn_isNull(t_accn_no)) {
        FSCommUtil.gfn_validate(this, '계좌번호를 입력해 주십시오.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return
      } else if (FSCommUtil.gfn_length(t_accn_no) != 10 || !FSCommUtil.gfn_isNum(t_accn_no)) {
        FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return
      } else if (this.lv_acct_list.length == 0) {
        FSCommUtil.gfn_validate(this, '검색할 계좌가 없습니다.')
        return
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570142_S'
      this.eaiCommObj.params = {
        acct_no: this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, // 계좌번호
        trd_kind: this.lv_ed_trd_kind.value, // 거래구분
        fromactdt: this.lv_ed_stc_from.value.replace(/-/g, ''), // 기준일시작
        toactdt: this.lv_ed_stc_to.value.replace(/-/g, ''), // 기준일끝
        media_gb: this.lv_ed_media_gb.value, // 매체구분
        summ_detail_yn: 'N' // 적요상세
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_TradeListSearchResult)
    },

    fn_TradeListSearchResult(pResultData) {
      console.log('fn_TradeListSearchResult.....')

      let t_data = pResultData.data.rp251500_O_00VO
      console.log(t_data)

      // 초기화
      this.lv_trd_list = []

      if (FSCommUtil.gfn_length(t_data) > 0) {
        t_data.forEach((item, idx) => {
          item.trd_dt = FSCommUtil.gfn_dateReplace(1, item.trd_dt)

          //계좌번호 치환
          if (item.bnk_accn_no_encr.length >= 8) {
            //계좌번호가 8자리 이상인 경우
            item.bnk_accn_no_encr = item.bnk_accn_no_encr.substr(0, 3) + '*****' + item.bnk_accn_no_encr.substr(8)
          }
          if (item.bnk_accn_no_encr.length < 8 && item.bnk_accn_no_encr.length > 0) {
            //계좌번호가 8자리 미만인 경우
            item.bnk_accn_no_encr = item.bnk_accn_no_encr.substr(0, 3) + '*'.repeat(item.bnk_accn_no_encr.length - 3)
          }
        })
        this.lv_trd_list = t_data
      }
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event, flag) {
      console.log('fn_SearchValid.....')

      let t_accn_no = this.lv_ed_accn_no.value

      if (FSCommUtil.gfn_length(t_accn_no) >= 10) {
        this.lv_ed_accn_no.error = false

        if (!FSCommUtil.gfn_isNum(t_accn_no)) {
          FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return
        } else {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || flag == 'P' || this.isMobile) {
            // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

            // 계좌목록조회
            this.fn_AccnNoList(t_accn_no)
          }
        }
      } else if (t_accn_no.length > 7 || event.key == 'Delete' || event.key == 'Backspace') {
        // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

        // 계좌목록/고객명 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true
        this.lv_ed_cust_nm.value = ''

        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()
      }
    }
  }
}
</script>
<style scoped></style>
